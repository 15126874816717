import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment:ENV = {
    production: false,
    apiUrl: 'https://backend.testing.clubbolivar.com/',
    type: 'club-bolivar',
    title: 'Club Bolívar',
    subtitle: 'Membresías',
    description: `"Club Bolívar - Membresías" es un sitio en el que puedes administrar tus membresías, como las de socios, entradas y abonos.`,
    keywords: 'club, bolivar, campeon, clubbolivar, membresias, socios, abonos, entradas, tickets',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.svg',
    imgLogoBig: '/assets/img/logo-big.png',
    templateType: 'template-1',
    theme: 'light',
    changeTheme: false,

    dashboard: false,
    disclaimer: true,
    header: true,
    sidebar: true,
    footer: true,
    preFooter: true,

    cart: false,

    authType: 'auth-1',
    authGoogle: true,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'home',

    processCartType: 'type-1',
    processCartTitle: 'Juntos construimos este sueño',

    multiplePayments: true,
    multipleProfiles: true,

    loaderType: 1,
    loaderDefaultText: 'Cargando...',

    //* Firebase
    firebase: {
        "projectId":"clubbolivar-membresias",
        "appId":"1:924925915724:web:9f327aa12495b5d7c9fae9",
        "storageBucket":"clubbolivar-membresias.appspot.com",
        "apiKey":"AIzaSyBn7I3w7JFAJcP-AVJNWhht25jhyGUgN2M",
        "authDomain":"clubbolivar-membresias.firebaseapp.com",
        "messagingSenderId":"924925915724",
        "measurementId":"G-J741BLEVFD",
    },

    keyEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',
    ivEncript: '3fa85f64c2f9e0c53fa85f64c2f9e0c5',

    country: 'bo',
};
