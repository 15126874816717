
 import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { AlertService } from './alert.service';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
import { getEnvironment } from '../environment/environment';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

export interface Data {
    title: string;
}

@Injectable({
    providedIn: 'root',
})
export class XtrasService {

    env = getEnvironment();

    private emitChangeSource: BehaviorSubject<Data> = new BehaviorSubject<Data>({title: 'Administrador'});

    get sharingObservable() {
        return this.emitChangeSource.asObservable();
    }

    set sharingObservableData(data: Data) {
        this.emitChangeSource.next({...data});
    }

    constructor(
        private load: LoadingService,
        private toast: NotificationService,
        private alert: AlertService,
        private router: Router,
    ) {}

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    formatDate(date?: any) {
        const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
        if(!date) return '-';
        const dateFormat = new Date(date);
        return `${monthNames[dateFormat.getMonth()]} ${dateFormat.getDate()}, ${dateFormat.getFullYear()}`;
    }

    formatDateTime(date: any) {
        if(date) {
            const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agostos', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

            const dateFormat = new Date(date);
            return `${dateFormat.getDate()}/${('0' + (dateFormat.getMonth()+1)).slice(-2)}/${dateFormat.getFullYear()} - ${('0' + dateFormat.getHours()).slice(-2)}:${('0' + dateFormat.getMinutes()).slice(-2)}:${('0' + dateFormat.getSeconds()).slice(-2)}`;
        } else {
            return '-';
        }
    }

    generateMinToken() {
        var token = '';
        var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';
        for (let i = 1; i <= 8; i++) {
            var char = Math.floor(Math.random() * str.length + 1);
            token += str.charAt(char)
        }

        return token;
    }

    removeObjectWithVar(array: any, vr: any, filtVr: any, vr2: any, filtVr2: any) {
        console.log(vr);
        console.log(filtVr);
        const objWithIdIndex = array.findIndex((obj: any) => {
            obj[vr] == filtVr;
            obj[vr2] == filtVr2;
        });
        array.splice(objWithIdIndex, 1);

        return array;
    }

    numericOnly(event: any): boolean {
        let pattern = /^([0-9])$/;
        let result = pattern.test(event.key);
        return result;
    }

    getFullName(name: string, lastName?: string, motherLastName?: string) {
        return `${name} ${lastName ?? ''} ${motherLastName ?? ''}`
    }

    getAction(action: string): string {
        switch (action) {
            case 'edit':
                return 'Editar';
        
            case 'read':
                return 'Ver';
            
            default:
                return 'Crear';
        }
    }

    async getFormData(data: { [key: string]: any }): Promise<FormData> {
        let formData = new FormData();

        await Promise.all(Object.entries(data).map( ([key, val]:any) => {
            if (val !== null && val !== undefined && val !== '') {
                formData.append(key, val);
            } 
        }));
      
        return formData;
    }

    getErrorApi(result: any, loading:boolean = false, redirectError: string = '/', errorDefault?:string) {
        if(loading) {
            this.load.dismiss();
        }
        if(result?.others?.redirect_url) {
            this.router.navigateByUrl(result?.others?.redirect_url);
            this.alertErrorApi(result, errorDefault);
            return;
        }
        if(result['redirect']) {
          this.router.navigateByUrl(redirectError);
        }
        this.alertErrorApi(result, errorDefault);
    }

    alertErrorApi(result: any, errorDefault?: string) {
        if(result['message']) {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: result['message'],
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.alert.alertCapYei({
                    type: 'error',
                    message: result['message'],
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: 'No se pudo obtener la información, intente nuevamente.'
                });
            }
        } else {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: '¡Error!',
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.toast.error('¡Error!', errorDefault ?? 'No se pudo obtener la información, intente nuevamente.', 4000);
            }
        }
    }

    getIDDateTime() {
		var today = new Date();
		var y = today.getFullYear();
		// JavaScript months are 0-based.
		var m = today.getMonth() + 1;
		var d = today.getDate();
		var h = today.getHours();
		var mi = today.getMinutes();
		var s = today.getSeconds();
		return y + '_' + m + '_' + d + '_' + h + '_' + mi + '_' + s;
	}

    downloadFile(file: any, fileName: string, typeFile: string) {

        //*Hasta que habiliten coors
        const link = document.createElement('a');
        
        link.href = file;
        link.download = fileName;
        link.target = '_blank';
      
        link.style.display = 'none';
        document.body.appendChild(link);
      
        link.click();
      
        setTimeout(() => {
            document.body.removeChild(link);
        }, 100);

        // let blobData;
        // let name = fileName.replace(/\//g, '-');
        // if (file.startsWith('http') || file.startsWith('https')) {
        //     // Si es una URL, se hace una petición para obtener el Blob
        //     fetch(file, {
        //         // headers: {
        //         //     'Content-Type': 'application/json',
        //         //     'Access-Control-Allow-Origin': '*'
        //         // },

        //         // mode: 'no-cors'
        //     })
        //     .then(response => response.blob())
        //     .then((blob): any => {
        //         if(blob instanceof Blob) {
        //             this.downloadBlob(blob, name);
        //         } else if(typeof blob === 'string' && this.isBase64(blob)) {
        //             blobData = this.base64ToBlob(blob, typeFile);
        //             this.downloadBlob(blobData, name);
        //         } else {
        //             console.error('response.data no es un Blob ni base64');
        //             alert('El archivo a descargar no tiene un formator válido');
        //             return null;
        //         }
        //     })
        //     .catch(error => console.error(error));
        // } else {
        //     // Si es una cadena base64, se convierte a Blob
        //     blobData = this.base64ToBlob(file, typeFile);
        //     if(blobData)
        //     this.downloadBlob(blobData, name);
        // }
    }

    isBase64 = (str: string) => {
        try {
            atob(str);
            return true;
        } catch {
            return false;
        }
    }

    base64ToBlob(base64: string, typeFile: string) {
        let _base64 = base64;
        if(base64.includes('data:')) {
            _base64 = base64.substring(base64.indexOf(',') + 1);
        }
        const byteCharacters = atob(_base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: typeFile });
    }

    downloadBlob(blob: Blob, fileName: string) {
        const urlBlob = URL.createObjectURL(blob);
        const link = document.createElement('a');
      
        link.href = urlBlob;
        link.download = fileName;
      
        // Esto es necesario para que la descarga funcione en Firefox
        link.style.display = 'none';
        document.body.appendChild(link);
      
        link.click();
      
        // Se revoca la URL del Blob y se elimina el enlace del DOM
        setTimeout(() => {
            URL.revokeObjectURL(urlBlob);
            document.body.removeChild(link);
        }, 100);
    }

    checkPasswords: ValidatorFn = (group: AbstractControl): {[key: string]: any} | null => {
        const password = group.get('password');
        const confirm_password = group.get('confirm_password');
        return password && confirm_password && password.value === confirm_password.value ? null : { notSame: true };
    }

    getForProject(type: 'title-shop' | 'subtitle-product'): any {
        if (type == 'title-shop') {
            switch (this.env.type) {
                case 'club-bolivar':
                    return `TIENDA BOLÍVAR ${ this.env.yearProyect }`;
                case 'club-colocolo':
                    return `TIENDA COLO-COLO ${ this.env.yearProyect }`;
                case 'the-strongest':
                    return `Tienda ${ this.env.title } ${ this.env.yearProyect }`;
                case 'wilstermann':
                    return 'Nuestros Abonos';
                case 'alianza':
                    return 'Pagos';
                case 'race-ligalapaz':
                    return 'Carreras';
                case 'spazio':
                    return 'Inscripciones';
                default:
                    return 'Tienda';
            }
        } else if(type == 'subtitle-product') {
            switch (this.env.type) {
                case 'club-bolivar':
                    return null;
                case 'club-colocolo':
                    return null;
                case 'the-strongest':
                    return `Abonado Gualdinegro`;
                case 'wilstermann':
                    return '<img src="/assets/img/icons/star.png" alt="star"class="inline-block"> <span class="align-middle"> ¡Adquiere tu abono! </span> <img src="/assets/img/icons/star.png" alt="star"class="inline-block">';
                case 'alianza':
                    return 'Pagos';
                default:
                    return null;
            }
        }
    }

    getTerms(): any {
        switch (this.env.type) {
            case 'the-strongest':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al Club The Strongest.<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club The Strongest.<br>
                8. El Club The Strongest se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
            case 'wilstermann':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al Club Wilstermann.<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Wilstermann.<br>
                8. El Club Wilstermann se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
            case 'club-bolivar':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al Club Bolívar.<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Bolívar.<br>
                8. El Club Bolívar se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
            case 'club-colocolo':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al Club Colo-Colo.<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Colo-Colo.<br>
                8. El Club Colo-Colo se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
            case 'fexco':
                return `
                El adquiriente de la presente entrada se hace total responsable por la información y claves de acceso que contenga dicha entrada, ya que dicha información será entregada y/o enviada únicamente al adquiriente de dicha entrada. Al ser una entrada digital, la misma puede ser utilizada para ingresar al evento en formato físico impreso o en formato digital mediante un dispositivo electrónico, por lo que la persona que presente dichas claves de acceso en el ingreso al evento podrá ingresar siempre y cuando la misma no haya ingresado previamente al mismo. En caso de que dichas claves de acceso caiga en manos de terceros el único responsable será el adquiriente de la entrada.<br>
                La ejecución en la fecha, día y hora del evento para el cual es válida la presente entrada es de plena y única responsabilidad de la empresa productora del evento y en ningún caso de Todotix SRL. Los datos de la empresa o persona responsable del evento para cual la presente entrada es válida serán impresas en la parte anterior de esta entrada. Todotix SRL se libra de cualquier daño, lesión y/o muerte causada directa o indirectamente por la participación o asistencia al evento para la cual la presente entrada es válida. No se aceptan cambios. No se aceptan devoluciones. No se otorgan reembolsos. Entradas perdidas no serán reemplazadas ni reembolsadas. La devolución de dinero por la cancelación o no realización del evento será responsabilidad del organizador de eventos. Condiciones y términos sujetos escribir a legal@todotix.com. No será permitido el ingreso al evento con pirotecnia, grabadores, filmadoras, cámaras de fotografía y de video, ni cualquier elemento similar a los mencionados a criterio del Organizador, pudiendo los mismos ser retirados del lugar y destruido su contenido. El Organizador podrá solicitar que se apague todo equipo de radio llamada o teléfono celular antes del acceso al evento. El adquiriente de la presente entrada es responsable de revisar, aceptar y confirmar los términos y condiciones de su compra previo a realizarla. La edad mínima para la cual un niño o niña debe pagar la entrada será determinado por el organizador del evento. Todas las ventas son finales.
                `;
        
            case 'oriente-petrolero':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al Club Oriente Petrolero.<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del Club Oriente Petrolero.<br>
                8. El Club Oriente Petrolero se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
        
            case 'san-jose':
                return `
                1. El abono adquirido es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la sociedad.<br>
                3. El comprador debe pagar el precio acordado por el abono, ya sea al contado o en el plan de cuotas permitido.<br>
                4. En caso de que el abono haya sido adquirida a plan de cuotas, el comprador acepta que en caso de que incumpla cualquiera de los pagos, se bloquea el derecho de todos los beneficios del abono hasta que el comprador cumpla con sus cuotas correspondientes.<br>
                5. El comprador acepta que dicho abono es un aporte voluntario al "Club GV San José".<br>
                6. El comprador no puede utilizar el abono adquirido para fines ilegales o inmorales.<br>
                7. El comprador no puede utilizar el abono adquirido para fines publicitarios sin el consentimiento previo del "Club GV San José".<br>
                8. El "Club GV San José" se reserva el derecho de revocar el abono adquirido si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
        
            case 'race-ligalapaz':
                return `
                1. El entrada adquirida es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la entrada.<br>
                3. El comprador debe pagar el precio acordado por la entrada.<br>
                4. El comprador no puede utilizar la entrada adquirida para fines ilegales o inmorales.<br>
                5. El comprador no puede utilizar la entrada adquirida para fines publicitarios sin el consentimiento previo de Liga La Paz<br>
                6. Liga La Paz se reserva el derecho de revocar la entrada adquirida si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
            case 'eat-out':
                return `
                1. La entrada adquirida es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir dicha entrada.<br>
                3. El comprador debe pagar el precio acordado.<br>
                5. El comprador acepta que dicha compra es no reembolsable.<br>
                `;
            case 'kermesse-ctlp':
                return `
                1. La entrada adquirida es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir dicha entrada.<br>
                3. El comprador debe pagar el precio acordado.<br>
                5. El comprador acepta que dicha compra es no reembolsable.<br>
                `;
            case 'spazio':
                return `
                1. El membresía adquirida es personal e intransferible.<br>
                2. El comprador debe ser mayor de edad y tener capacidad legal para adquirir la membresía.<br>
                3. El comprador debe pagar el precio acordado por la membresía.<br>
                4. El comprador no puede utilizar la membresía adquirida para fines ilegales o inmorales.<br>
                5. El comprador no puede utilizar la membresía adquirida para fines publicitarios sin el consentimiento previo del Gimnasio Spazio<br>
                6. Gimnasio Spazio se reserva el derecho de revocar la membresía adquirida si el comprador incumple cualquiera de los términos y condiciones establecidos.<br>
                `;
        }
    }

    encryptUsingAES256(data: any): string {
        let _key = CryptoJS.enc.Hex.parse(this.env.keyEncript);
        let _iv = CryptoJS.enc.Hex.parse(this.env.ivEncript);
        let encrypted = CryptoJS.AES.encrypt(
            JSON.stringify(data), _key, {
            keySize: 256 / 8,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString();
        return encrypted;
    }
    
    decryptUsingAES256(encrypted: string): any {
        let _key = CryptoJS.enc.Hex.parse(this.env.keyEncript);
        let _iv = CryptoJS.enc.Hex.parse(this.env.ivEncript);
    
        let decrypted = CryptoJS.AES.decrypt(
            encrypted, _key, {
            keySize: 256 / 8,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        console.log(decrypted);
    
        try {
            return JSON.parse(decrypted);
        } catch (e) {
            console.error('Error al desencriptar:', e);
            return null;
        }
    }

    getCountryCredentialExpedition(): {id:number|string, name:string}[] {
        let expeditionData:{id:number|string, name:string}[] = [];
        if(this.env.country == 'bo'){
            expeditionData = [
                { id: 'CB', name: 'Cochabamba' },
                { id: 'LP', name: 'La Paz' },
                { id: 'SC', name: 'Santa Cruz' },
                { id: 'CH', name: 'Chuquisaca' },
                { id: 'TA', name: 'Tarija' },
                { id: 'OR', name: 'Oruro' },
                { id: 'PO', name: 'Potosí' },
                { id: 'BE', name: 'Beni' },
                { id: 'PA', name: 'Pando' },
                { id: 'OTRO', name: 'Otro' },
            ];
        }
        return expeditionData;
    }
}
